import { storyData } from '../building-future/climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/nodiscrimination/education_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/nodiscrimination/education_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/nodiscrimination/education_card_img3@2x.jpg';
import WedoIco1 from '@/assets/img/nodiscrimination/education_obj1_1@2x.png';
import WedoIco2 from '@/assets/img/nodiscrimination/education_obj1_2@2x.png';
import WedoIco3 from '@/assets/img/nodiscrimination/education_obj1_3@2x.png';
import WedoIco4 from '@/assets/img/nodiscrimination/education_obj1_4@2x.png';
import WedoIco31 from '@/assets/img/nodiscrimination/education_obj2020_1@2x.png';
import WedoIco32 from '@/assets/img/nodiscrimination/education_obj2020_2@2x.png';
import WedoIco33 from '@/assets/img/nodiscrimination/education_obj2020_3@2x.png';
import WedoIco5 from '@/assets/img/nodiscrimination/education_obj2_1@2x.png';
import WedoIco6 from '@/assets/img/nodiscrimination/education_obj2_2@2x.png';
import WedoIco7 from '@/assets/img/nodiscrimination/education_obj2_3@2x.png';
import EducationTagetObj from '@/assets/img/nodiscrimination/education_sec2_obj2@2x.png';
import EducationTagetObjM from '@/assets/img/nodiscrimination/education_sec2_obj2M@2x.png';
import AccordionComponent, { AccordionItem } from '@/components//Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
  SectionWedo,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `접근성 향상`,
    dec: `유니세프는 모든 어린이가 자신이 누구이고, 어디에 살고 있고, 어떤 상황에 놓여 있고, 가족의 경제적 상황이 어떠하든 간에 학교에 가서 배울 수 있도록 지원합니다. 특히 지역사회 모니터링을 기반으로 정부가 교육 시스템을 개선하여 성별·지역·소득 수준·장애 유무 등 여러 교육 장벽을 개선할 수 있도록 합니다. 학교 밖 어린이에 대한 정보를 제공하며 더 많은 어린이가 교육 시스템 내에서 배울 수 있도록 정부와 협력합니다. `,
    img: CardImg1,
  },
  {
    id: 2,
    title: `학업 능력 향상`,
    dec: `유니세프는 모든 어린이가 체계적인 교육 시스템 속에서 연령에 따른 적절한 교육을 받을 수 있도록 지원합니다. 특히 영유아 교육 확대는 학업 능력 향상을 위한 핵심 전략입니다. 영유아 교육은 체계적인 학교 교육 시스템의 초석이 되기 때문입니다. 이를 위해 교육 자료 개발, 교사 훈련, 지역사회 참여 교육 등을 진행합니다. 또한 정부가 각국의 학업 능력을 체계적으로 평가하여 교육 정책을 펼칠 수 있도록 협력합니다. 디지털 교과서, 태양열 라디오 등 모든 어린이가 빠짐없이 배울 수 있도록 혁신적인 교육법도 개발합니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `기술 능력 향상`,
    dec: `유니세프는 청년들이 건강한 미래를 설계하는 데 필요한 기술을 익힐 수 있도록 지원합니다. 문장 독해력과 산술 능력을 배우는 기초 기술부터 직업을 마련하는 데 필요한 기술, 디지털을 활용한 기술 등을 배울 수 있도록 지원합니다.`,
    img: CardImg3,
  },
];

const wedo = [  
  {
    id: 1,
    tit: `2019년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco5,
        desc: [
          {
            dec: `학교에 다니지 못하는 어린이 <strong>1,700만 명</strong> 에게 교육 서비스 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco6,
        desc: [
          {
            dec: `어린이 <strong>1,200만 명</strong>에게 교육물품 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco7,
        desc: [
          {
            dec: `어린이 및 청소년 <strong>400만 명</strong>에게 직업 훈련 서비스 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2020년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `학교에 다니지 못하는 어린이 <strong>4,800만 명</strong>에게 교육 서비스 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco32,
        desc: [
          {
            dec: `어린이 <strong>4,300만 명</strong>에게 교육 자료 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco33,
        desc: [
          {
            dec: `어린이 및 청소년 <strong>770만 명</strong>에게 기술 개발 프로그램 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco2,
        desc: [
          {
            dec: `난민 어린이 <strong>230만 명</strong>에게 원격 수업 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2021년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `학교 밖 어린이 <strong>4,860만 명</strong>에게 교육 서비스 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `난민 어린이 <strong>640만 명</strong>에게 교육 서비스 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco33,
        desc: [
          {
            dec: `91개국 어린이 <strong>3,300만 명</strong>에게 기술 교육 프로그램 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco32,
        desc: [
          {
            dec: `어린이 <strong>4,200만 명</strong>에게 학용품 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2022년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `학교 밖 어린이 <strong>3,790만 명</strong>에게 교육 서비스 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `난민 어린이 <strong>310만 명</strong>에게 <br />교육 서비스 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco33,
        desc: [
          {
            dec: `학교 관계자 <strong>8만 5,099명</strong>에게 <br />훈련 프로그램 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco32,
        desc: [
          {
            dec: `어린이 <strong>2,830만 명</strong>에게 <br />교육 용품 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    tit: `2023년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `난민 어린이 등 위기에 처한<br/>어린이 <strong>3,770만 명</strong>에게 교육기회 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `긴급상황 등 취약 지역 어린이<br/><strong>3,120만 명</strong>에게 학습자료 지원`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco33,
        desc: [
          {
            dec: `<strong>92개국</strong> 어린이 <strong>2,190만 명</strong>이 시민사회활동 참여(2022년 대비 580만 명 증가)`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco32,
        desc: [
          {
            dec: `원격 학습 플랫폼 <strong>7개국</strong> 출시(누적 38개국), 600만 명 등록`,
          },
        ],
      },
    ],
  },
];

const NavigationButton = styled.div``;

const Education: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST02'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A005'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="교육"
      description="for every child, an education"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 유아기부터 청년기까지
                  <br className="small-hide" /> 양질의 교육을 누릴 권리가
                  있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                유니세프는 전 세계 모든 어린이가 필요한 지식과 기술을 익힐 수
                있도록 교육을 지원합니다. 소외되고 배제된 지역에 사는 어린이도
                학교에 다닐 수 있도록 교육 접근성을 강화하고, 어떠한 위기
                상황에서도 교육을 중단하지 않도록 전문 인력 양성과 양질의 교육
                프로그램, 필요한 학용품을 지원합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="q2p2HSyTYH0" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionTarget className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <div className="col-header">
              <h2>
                <Tit size="s1">
                  2022-2025 <br />
                  유니세프 중점 사업 목표
                </Tit>
              </h2>
              <ul className="blit">
                <li>
                  초·중·고등학교 졸업률 <strong>증가</strong>
                </li>
                <li>
                  형평성 지수로 측정한 학습 결과의 형평성 <strong>개선</strong>
                </li>
                <li>
                  전 세계 학습 빈곤율 <strong>감소</strong>
                </li>
              </ul>
            </div>
            <Image pcSrc={EducationTagetObj} mobileSrc={EducationTagetObjM} />
          </SectionHeader>
        </Container>
      </SectionTarget>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                모든 어린이의 교육을 위해, <br /> 전 세계 파트너와 함께
                유니세프가 한 일
              </Tit>
            </h2>
          </SectionHeader>
          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList
                    itemData={row.child}
                    type={row.col ? row.col : ``}
                  />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedo>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation onPaginationHide>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A005" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Education;
